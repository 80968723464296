<template>
  <div id="homeBox">
    <div class="">
      <div class="flex margin-bottom-16">
        <!-- 营业统计（实时数据) -->
        <div
          class="
            col-md-6
            bg-white
            margin-right-16
            padding-tb-32 padding-lr-16
            radius-4
          "
          style="height: 286px"
        >
          <p class="col-12 title">营业统计(实时数据)</p>
          <div
            class="row solid-bottom padding-bottom-32 margin-top-24"
            style="margin-left: 0"
          >
            <div
              class="flex flex-direction justify-start padding-left-16 col-md-4"
            >
              <span class="text-price home_price">{{
                allData.order_today_money
              }}</span>
              <div class="flex align-center text-12" style="margin-top: 6px">
                <!-- 下降 -->
                <span
                  v-if="
                    allData.order_today_money - allData.order_yesterday_money <
                    '0'
                  "
                >
                  <span class="text-gray">比昨日收益</span
                  ><i class="el-icon-bottom text-l-green"></i>
                  <span class="text-l-green">{{
                    Math.abs(
                      allData.order_today_money - allData.order_yesterday_money
                    ).toFixed("2")
                  }}</span>
                </span>
                <!-- 增加 -->
                <span
                  v-if="
                    allData.order_today_money - allData.order_yesterday_money >
                    '0'
                  "
                >
                  <span class="text-gray">比昨日收益</span
                  ><i class="el-icon-top text-red"></i>
                  <span class="text-red">{{
                    Math.abs(
                      allData.order_today_money - allData.order_yesterday_money
                    ).toFixed("2")
                  }}</span>
                </span>
                <!-- 持平 -->
                <span
                  v-if="
                    allData.order_today_money - allData.order_yesterday_money ==
                    '0'
                  "
                >
                  <span class="text-gray">与昨日收益</span
                  ><i class="el-icon-minus text-gray"></i>
                  <span class="text-l-green">持平</span>
                </span>
              </div>
            </div>
            <div
              class="flex flex-direction justify-start padding-left-16 col-md-4"
            >
              <span class="text-price home_price">11705.54</span>
              <div class="flex align-center text-12" style="margin-top: 6px">
                <!-- 下降 -->
                <span
                  v-if="
                    allData.order_thismonth_money -
                      allData.order_thismonth_money <
                    '0'
                  "
                >
                  <span class="text-gray">本月收益</span
                  ><i class="el-icon-bottom text-l-green"></i>
                  <span class="text-l-green">{{
                    Math.abs(
                      allData.order_thismonth_money -
                        allData.order_thismonth_money
                    ).toFixed("2")
                  }}</span>
                </span>
                <!-- 增加 -->
                <span
                  v-if="
                    allData.order_thismonth_money -
                      allData.order_thismonth_money >
                    '0'
                  "
                >
                  <span class="text-gray">本月收益</span
                  ><i class="el-icon-top text-red"></i>
                  <span class="text-red">{{
                    Math.abs(
                      allData.order_thismonth_money -
                        allData.order_thismonth_money
                    ).toFixed("2")
                  }}</span>
                </span>
                <!-- 持平 -->
                <span
                  v-if="
                    allData.order_thismonth_money -
                      allData.order_thismonth_money ==
                    '0'
                  "
                >
                  <span class="text-gray">本月收益</span
                  ><i class="el-icon-minus text-gray"></i>
                  <span class="text-l-green">持平</span>
                </span>
              </div>
            </div>
            <div
              class="
                flex flex-direction
                justify-start
                padding-left-16
                col-md-4
                solid-left
              "
            >
              <span class="text-price home_price">11705.54</span>
              <div class="flex align-center text-12" style="margin-top: 6px">
                <!-- 下降 -->
                <span
                  v-if="
                    allData.refund_today_money +
                      allData.income_today_money -
                      allData.refund_yesterday_money -
                      allData.income_yesterday_money <
                    '0'
                  "
                >
                  <span class="text-gray">比昨日支出</span
                  ><i class="el-icon-bottom text-l-green"></i>
                  <span class="text-l-green">{{
                    Math.abs(
                      allData.refund_today_money +
                        allData.income_today_money -
                        allData.refund_yesterday_money -
                        allData.income_yesterday_money
                    ).toFixed("2")
                  }}</span>
                </span>
                <!-- 增加 -->
                <span
                  v-if="
                    allData.refund_today_money +
                      allData.income_today_money -
                      allData.refund_yesterday_money -
                      allData.income_yesterday_money >
                    '0'
                  "
                >
                  <span class="text-gray">比昨日支出</span
                  ><i class="el-icon-top text-red"></i>
                  <span class="text-red">{{
                    Math.abs(
                      allData.refund_today_money +
                        allData.income_today_money -
                        allData.refund_yesterday_money -
                        allData.income_yesterday_money
                    ).toFixed("2")
                  }}</span>
                </span>
                <!-- 持平 -->
                <span
                  v-if="
                    allData.refund_today_money +
                      allData.income_today_money -
                      allData.refund_yesterday_money -
                      allData.income_yesterday_money ==
                    '0'
                  "
                >
                  <span class="text-gray">比昨日支出</span
                  ><i class="el-icon-minus text-gray"></i>
                  <span class="text-l-green">持平</span>
                </span>
              </div>
            </div>
          </div>
          <div class="row padding-top-32" style="margin-left: 0">
            <div
              class="flex flex-direction justify-start padding-left-16 col-md-4"
            >
              <span class="text-price home_price">11705.54</span>
              <div class="flex align-center text-12" style="margin-top: 6px">
                <!-- 下降 -->
                <span
                  v-if="
                    allData.income_thismonth_money +
                      allData.refund_thismonth_money -
                      allData.refund_lastmonth_money -
                      allData.income_lastmonth_money <
                    '0'
                  "
                >
                  <span class="text-gray">本月支出</span
                  ><i class="el-icon-bottom text-l-green"></i>
                  <span class="text-l-green">{{
                    Math.abs(
                      allData.income_thismonth_money +
                        allData.refund_thismonth_money -
                        allData.refund_lastmonth_money -
                        allData.income_lastmonth_money
                    ).toFixed("2")
                  }}</span>
                </span>
                <!-- 增加 -->
                <span
                  v-if="
                    allData.income_thismonth_money +
                      allData.refund_thismonth_money -
                      allData.refund_lastmonth_money -
                      allData.income_lastmonth_money >
                    '0'
                  "
                >
                  <span class="text-gray">本月支出</span
                  ><i class="el-icon-top text-red"></i
                  ><span class="text-red">{{
                    Math.abs(
                      allData.income_thismonth_money +
                        allData.refund_thismonth_money -
                        allData.refund_lastmonth_money -
                        allData.income_lastmonth_money
                    ).toFixed("2")
                  }}</span>
                </span>
                <!-- 持平 -->
                <span
                  v-if="
                    allData.income_thismonth_money +
                      allData.refund_thismonth_money -
                      allData.refund_lastmonth_money -
                      allData.income_lastmonth_money ==
                    '0'
                  "
                >
                  <span class="text-gray">本月支出</span
                  ><i class="el-icon-minus text-gray"></i
                  ><span class="text-l-green">持平</span>
                </span>
              </div>
            </div>
            <div
              class="flex flex-direction justify-start padding-left-16 col-md-4"
            >
              <span class="text-price home_price">11705.54</span>
              <div class="flex align-center text-12" style="margin-top: 6px">
                <span>
                  <span class="text-gray">本月支出</span>
                  <i class="el-icon-top text-red"></i>
                  <span class="text-red">7394.42</span>
                </span>
              </div>
            </div>
            <div
              class="
                flex flex-direction
                justify-start
                padding-left-16
                col-md-4
                solid-left
              "
            >
              <span class="text-price home_price">11705.54</span>
              <div class="flex align-center text-12" style="margin-top: 6px">
                <span>
                  <span class="text-gray">总支出金额</span>
                  <i class="el-icon-top text-red"></i>
                  <span class="text-red">7394.42</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-sub bg-gray radius-4">
          <div class="flex-sub bg-gray radius-4" style="height: 284px">
            <div class="flex margin-bottom-16">
              <!-- 当前在线用户量-->
              <div
                class="
                  bg-white
                  padding-16
                  flex-sub flex flex-direction
                  justify-between
                "
                style="height: 134px"
              >
                <div class="flex align-center">
                  <img
                    src="@/assets/img/index/add.png"
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <p class="home_title">当前在线用户量</p>
                </div>
                <div class="flex flex-direction justify-start">
                  <span class="text-bold text-24 text-left text-people">{{
                    allData.user_active_count
                  }}</span>
                </div>
              </div>
              <!-- 今日新增用户量-->
              <div
                class="
                  bg-white
                  padding-16
                  flex-sub flex flex-direction
                  justify-between
                  margin-lr-16
                "
                style="height: 134px"
              >
                <div class="flex align-center">
                  <img
                    src="@/assets/img/index/add.png"
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <p class="home_title">今日新增用户量</p>
                </div>
                <div class="flex flex-direction justify-start">
                  <span class="text-bold text-24 text-left text-people">{{
                    allData.user_today_reg_count
                  }}</span>
                  <div class="flex align-center text-12">
                    <!-- 下降 -->
                    <span
                      v-if="
                        allData.user_today_reg_count -
                          allData.user_yesterday_reg_count <
                        '0'
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-bottom text-l-green"></i>
                      <span class="text-l-green"
                        >{{
                          Math.abs(
                            allData.user_today_reg_count -
                              allData.user_yesterday_reg_count
                          ).toFixed("2")
                        }}人</span
                      >
                    </span>
                    <!-- 增加 -->
                    <span
                      v-if="
                        allData.user_today_reg_count -
                          allData.user_yesterday_reg_count >
                        '0'
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-top text-red"></i>
                      <span class="text-red"
                        >{{
                          Math.abs(
                            allData.user_today_reg_count -
                              allData.user_yesterday_reg_count
                          ).toFixed("2")
                        }}人</span
                      >
                    </span>
                    <!-- 持平 -->
                    <span
                      v-if="
                        allData.user_today_reg_count -
                          allData.user_yesterday_reg_count ==
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-minus text-l-green"></i>
                      <span class="text-gray">持平</span>
                    </span>
                  </div>
                </div>
              </div>
              <!-- 今日下单量-->
              <div
                class="
                  bg-white
                  padding-16
                  flex-sub- flex flex-direction
                  justify-between
                "
                style="height: 134px"
              >
                <div class="flex align-center">
                  <img
                    src="@/assets/img/index/add.png"
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <p class="home_title">今日下单量(实时数据)</p>
                </div>
                <div class="flex flex-direction justify-start">
                  <span class="text-bold text-24 text-left text-">{{
                    allData.order_today_count
                  }}</span>
                  <div class="flex align-center text-12">
                    <!-- 下降 -->
                    <span
                      v-if="
                        allData.order_today_count -
                          allData.order_yesterday_count <
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-bottom text-l-green"></i>
                      <span class="text-l-green">{{
                        Math.abs(
                          allData.order_today_count -
                            allData.order_yesterday_count
                        ).toFixed("2")
                      }}</span>
                    </span>
                    <!-- 增加 -->
                    <span
                      v-if="
                        allData.order_today_count -
                          allData.order_yesterday_count >
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-top text-red"></i>
                      <span class="text-red">{{
                        Math.abs(
                          allData.order_today_count -
                            allData.order_yesterday_count
                        ).toFixed("2")
                      }}</span>
                    </span>
                    <!-- 持平 -->
                    <span
                      v-if="
                        allData.order_today_count -
                          allData.order_yesterday_count ==
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-minus text-l-green"></i>
                      <span class="text-gray">持平</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <!-- 今日新增站长-->
              <div
                class="
                  bg-white
                  padding-16
                  flex-sub flex flex-direction
                  justify-between
                "
                style="height: 134px"
              >
                <div class="flex align-center">
                  <img
                    src="@/assets/img/index/add.png"
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <p class="home_title">今日新增站长</p>
                </div>
                <div class="flex flex-direction justify-start">
                  <span class="text-bold text-24 text-left text-">{{
                    allData.zz_today_reg_count
                  }}</span>
                  <div class="flex align-center text-12">
                    <!-- 下降 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count <
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-bottom text-l-green"></i>
                      <span class="text-l-green">{{
                        Math.abs(
                          allData.zz_today_reg_count -
                            allData.zz_yesterday_reg_count
                        ).toFixed("2")
                      }}</span>
                    </span>
                    <!-- 增加 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count >
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-top text-red"></i>
                      <span class="text-red">{{
                        Math.abs(
                          allData.zz_today_reg_count -
                            allData.zz_yesterday_reg_count
                        ).toFixed("2")
                      }}</span>
                    </span>
                    <!-- 持平 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count ==
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-minus text-l-green"></i>
                      <span class="text-gray">持平</span>
                    </span>
                  </div>
                </div>
              </div>
              <!-- 总站长人数-->
              <div
                class="
                  bg-white
                  padding-16
                  flex-sub
                  margin-lr-16
                  flex flex-direction
                  justify-between
                "
                style="height: 134px"
              >
                <div class="flex align-center">
                  <img
                    src="@/assets/img/index/add.png"
                    style="width: 16px; height: 16px; margin-right: 8px"
                  />
                  <p class="home_title">站长总人数</p>
                </div>
                <div class="flex flex-direction justify-start">
                  <span class="text-bold text-24 text-left text-">{{
                    allData.zz_all_count
                  }}</span>
                  <div class="flex align-center text-12">
                    <!-- 下降 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count <
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-bottom text-l-green"></i>
                      <span class="text-l-green">{{
                        Math.abs(
                          allData.zz_today_reg_count -
                            allData.zz_yesterday_reg_count
                        ).toFixed("2")
                      }}</span>
                    </span>
                    <!-- 增加 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count >
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-top text-red"></i>
                      <span class="text-red">{{
                        Math.abs(
                          allData.zz_today_reg_count -
                            allData.zz_yesterday_reg_count
                        ).toFixed("2")
                      }}</span>
                    </span>
                    <!-- 持平 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count ==
                        0
                      "
                    >
                      <span class="text-gray">昨日新曾</span>
                      <i class="el-icon-minus text-l-green"></i>
                      <span class="text-gray">持平</span>
                    </span>
                  </div>
                </div>
              </div>
              <!-- 未处理留言-->
              <div
                class="
                  bg-white
                  padding-16
                  flex-sub- flex flex-direction
                  justify-between
                "
                style="height: 134px"
              >
                <div class="flex align-center">
                  <p class="home_title" style="color: #faad14">未处理留言</p>
                </div>
                <div class="flex flex-direction justify-start">
                  <span class="text-bold text-24 text-left text-">{{
                    allData.feedback_all_count
                  }}</span>
                  <div class="flex align-center text-12">
                    <!-- 下降 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count <
                        0
                      "
                    >
                      <span class="text-gray">昨日留言</span>
                      <i class="el-icon-bottom text-l-green"></i>
                      <span class="text-l-green">{{
                        Math.abs(
                          allData.zz_today_reg_count -
                            allData.zz_yesterday_reg_count
                        ).toFixed("2")
                      }}</span>
                    </span>
                    <!-- 增加 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count >
                        0
                      "
                    >
                      <span class="text-gray">昨日留言</span>
                      <i class="el-icon-top text-red"></i>
                      <span class="text-red">{{
                        Math.abs(
                          allData.zz_today_reg_count -
                            allData.zz_yesterday_reg_count
                        ).toFixed("2")
                      }}</span>
                    </span>
                    <!-- 持平 -->
                    <span
                      v-if="
                        allData.zz_today_reg_count -
                          allData.zz_yesterday_reg_count ==
                        0
                      "
                    >
                      <span class="text-gray">昨日留言</span>
                      <i class="el-icon-minus text-l-green"></i>
                      <span class="text-gray">持平</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--<div class="flex margin-bottom-16">
				<div class="col-md-6 bg-white margin-right-16 padding-tb-32 padding-lr-16 radius-4">
					1111
				</div>
			</div>-->
      <div class="flex">
        <div
          id="zx_change"
          class="flex-sub margin-right-16 flex flex-direction justify-between"
          style="height: 614px"
        >
          <div class="bg-white padding-16 radius-4" style="height: 298px">
            <div class="flex align-center justify-between">
              <span>总用户数</span>
              <div class="flex align-center" id="zx_input1">
                <el-select
                  @change="changeTime_user"
                  style="width: 120px"
                  v-model="user_time1"
                  placeholder="快捷选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>

                <el-date-picker
                  @change="changeTime_user"
                  style="width: 340px; margin-left: 16px"
                  v-model="user_time2"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerBeginDateBefore"
                  :default-value="timeDefaultShow"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
            <echart-line
              typeLine="userNum"
              company="人"
              :xData="xData1"
              :datas="datas1"
              :key="timer1"
            ></echart-line>
          </div>
          <div class="bg-white padding-16 radius-4" style="height: 298px">
            <div class="flex align-center justify-between">
              <span>总站长数</span>
              <div class="flex align-center" id="zx_input2">
                <el-select
                  @change="changeTime_station"
                  style="width: 120px"
                  v-model="station_time1"
                  placeholder="快捷选择"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>

                <el-date-picker
                  @change="changeTime_station"
                  style="width: 340px; margin-left: 16px"
                  v-model="station_time2"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="开始日期"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerBeginDateBefore"
                  :default-value="timeDefaultShow"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </div>
            </div>
            <echart-line
              typeLine="zhanzhang"
              company="人"
              :xData="xData2"
              :datas="datas2"
              :key="timer2"
            ></echart-line>
          </div>
        </div>
        <!-- 地图-->
        <div class="flex-sub bg-white radius-4" style="height: 614px"></div>
      </div>
    </div>
  </div>
</template>
<script>
// import {panelProup, Chart,Map} from './components'
//   import echartLine from './home/components/Line.vue'
import { homeData, homeUser, homeZz } from "@/api/http";
export default {
  data() {
    return {
      allData: {},
      /* 修改日期选择器结束日期*/
      pickerBeginDateBefore: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      timeDefaultShow: "",
      xData1: [], //总用户数x轴数据
      datas1: [], //总用户数Y轴数据
      timer1: "", //刷新用户数折线图
      xData2: [], //总站长数x轴数据
      datas2: [], //总站长数Y轴数据
      timer2: "", //刷新站长数折线图
      options: [
        {
          value: "999",
          label: "全部",
        },
        {
          value: "7",
          label: "最近七天",
        },
        {
          value: "15",
          label: "最近十五天",
        },
        {
          value: "30",
          label: "最近三十天",
        },
      ],
      user_time1: "",
      user_time2: "",
      station_time1: "",
      station_time2: "",
      zx_userData: {
        time: "",
      },
      zx_stationData: {
        time: "",
      },
    };
  },
  created() {
    this.timeDefaultShow = new Date();
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1);
    //获取统计数据
    this.getAllData();
  },
  methods: {
    changeTime_user(e) {
      console.log(e);
      if (e == "999" || e == "7" || e == "15" || e == "30") {
        this.user_time2 = "";
        this.zx_userData.time = e;
        console.log("总用户数：", this.zx_userData.time);
      } else {
        this.user_time1 = "";
        // var date = new Date(e[0])
        let time1 = Date.parse(new Date(e[0] + " 00:00:00")) / 1000;
        let time2 = Date.parse(new Date(e[1] + " 23:59:59")) / 1000;
        this.zx_userData.time = [time1, time2].toString();
        console.log("总用户数：", this.zx_userData.time);
      }
      this.getAllUserNum();
    },
    changeTime_station(e) {
      console.log(e);
      // console.log(e[0])
      if (e == "999" || e == "7" || e == "15" || e == "30") {
        this.station_time2 = "";
        this.zx_stationData.time = e;
        console.log("总用站长：", this.zx_stationData.time);
      } else {
        this.station_time1 = "";
        let time1 = Date.parse(new Date(e[0] + " 00:00:00")) / 1000;
        let time2 = Date.parse(new Date(e[1] + " 23:59:59")) / 1000;
        this.zx_stationData.time = [time1, time2].toString();
        console.log("总用站长：", this.zx_stationData.time);
      }
      this.getAllStation();
    },
    //获取统计数据
    getAllData() {
      homeData({}).then((res) => {
        console.log("统计数据：", res.data.data);
        let { code } = res.data;
        if (res.data.data.homeUser) {
          this.xData1 = res.data.data.homeUser[0];
          this.datas1 = res.data.data.homeUser[1];
          this.timer1 = new Date().getTime();
        }
        if (res.data.data.homeZz) {
          this.xData2 = res.data.data.homeZz[0];
          this.datas2 = res.data.data.homeZz[1];
          this.timer2 = new Date().getTime();
        }
        if (code == "1") {
          this.allData = res.data.data;
        }
      });
    },
    //总用户数据
    getAllUserNum() {
      let that = this;
      let tabDat = {};
      for (let item in this.zx_userData) {
        if (that.zx_userData[item] != "" && that.zx_userData[item] != "999") {
          tabDat[item] = that.zx_userData[item];
        }
      }
      homeUser(tabDat).then((res) => {
        console.log("总用户数据", res.data);
        if (res.data.code == "1") {
          this.xData1 = res.data.data[0];
          this.datas1 = res.data.data[1];
          this.timer1 = new Date().getTime();
        }
      });
    },
    //总站长数据
    getAllStation() {
      let that = this;
      let tabDat = {};
      for (let item in this.zx_stationData) {
        if (
          that.zx_stationData[item] != "" &&
          that.zx_stationData[item] != "999"
        ) {
          tabDat[item] = that.zx_stationData[item];
        }
      }
      homeZz(tabDat).then((res) => {
        console.log("总用户数据", res.data);
        if (res.data.code == "1") {
          this.xData2 = res.data.data[0];
          this.datas2 = res.data.data[1];
          this.timer2 = new Date().getTime();
        }
      });
    },
  },
};
</script>
<style lang="scss">
#homeBox {
  text-align: left;

  .title {
    float: left;
    color: #3f414c;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }

  .home_price {
    text-align: left;
    font-size: 24px;
    font-weight: bold;
  }
}
</style>
